import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, HostBinding , ViewChild} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { CartPage } from '../cart/cart.page';


import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-toppings',
  templateUrl: './toppings.page.html',
  styleUrls: ['./toppings.page.scss'],
})
export class ToppingsPage implements OnInit {
  @Input() isFromCrossSelling: boolean;

  @ViewChild('slides', { static: false }) slides: IonSlides;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    autoplay: false,
    autoHeight: true,
  };

  public current_slide_number = 0;
  public all_slides_count = 10;

  autoSelected: any = [];
  @HostBinding('style.--radio-color')
  public radioColor: any = (this.appService.appObject?.theme[this.appService.themeConfigFor]?.primaryColor ? this.appService.appObject?.theme[this.appService.themeConfigFor]?.primaryColor : '') + ' !important;';

  constructor(public modalController: ModalController, public appService: AppServiceService, private cdRef: ChangeDetectorRef) {
    // console.log(this.appService.selectedDish);
    this.appService.appObject.errors = [];
    this.appService.hideCartButton = true;

  }


  wizzardNext(){
    this.appService.validateDishSenarioToppingConfigsRealTime();
    console.log('real time ran');
    this.slides.slideNext();
    

  }
  wizzardBack(){

    this.slides.slidePrev();

  }


  public slide_errors = [];

  show_e(){
    console.log(this.slide_errors);
  }

  add_error_to_slide(){

  }

  async getSlideCount() {
 //   const count = await this.slides.length();
 //   console.log('Total number of slides:', count);
    return this.current_slide_number;
  }

  async updateCurrentSlide() {
    const index = await this.slides.getActiveIndex();
    const count = await this.slides.length();
    this.all_slides_count = count - 1;
    this.current_slide_number = index;
    console.log('Current slide index:', index);
    console.log('all slide index:', count);
    this.show_e();
  }

  calculateWidth(num1: number, num2: number): number {
    num1 = num1;
    num2 = num2 - 1;
    if (num2 === 0) {
      return 0;  // Avoid division by zero
    }
    return (num1 / num2) * 100;
  }


  tempClog(obj){
    console.log('errors');
    console.log(obj)
  }


  ngOnInit() {
  }

  ngDoCheck() {
    this.radioColor = this.appService.appObject.theme[this.appService.themeConfigFor]?.primaryColor || '';
  }

  // solution for ngCheckedAfterExpressionChanged exception, check it again 
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  async dismiss() {
    // console.log(this.appService.hideCartButton);
    this.modalController.dismiss({
      'dismissed': true
    });

    if (this.appService.cartIsOpen) {
      const modal = await this.modalController.create({
        component: CartPage,
        cssClass: 'custom-popup'
      });
      await modal.present();
    } else {
      this.appService.hideCartButton = false;
    }
  }

  /**
   * Will return only active scenarios
   * @param scenarioList scenario list to filter from 
   * @returns filtered scenario list
   */
  getActiveScenarios(scenarioList) {
    if (scenarioList && scenarioList.length) {
      let activeScenarios = scenarioList.filter((scenario) => scenario.status === 'true');
      return activeScenarios.sort((a,b) => a.position - b.position);
    } else {
      return [];
    }
  }

  /**
   * Will return status active topping list
   * @param toppingList topping list to filter from
   * @returns filtered topping list that status is active
   */
  getActiveToppings(toppingList) {
    if (toppingList && toppingList.length) {
      return toppingList.filter((topping) => {
        return topping.status === 'true'
      });
    } else {
      return [];
    }
  }

  autoSelect(topings, index, fun) {
    switch (fun) {
      case 'selectTopingOp':
        this.appService.selectTopingOp(null, index);
        break;

      default:
        break;
    }
    // console.log('auto', topings[0]['id']);
    return topings[0]['id'];
  }

  addFirstToping(id, index) {

    var ex = this.autoSelected.indexOf(id);
    if (ex == -1) {
      this.autoSelected.push(id);
      // console.log(id, index);
      this.appService.selectTopingOp2({ detail: { value: id } }, index);
    }

  }

}
